<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-form
      ref="checklistform"
      @submit.prevent="submitChecklist"
      lazy-validation
      v-model="valid"
    >
      <v-card class="c-bg-gray">
        <v-toolbar dark color="primary">
          <v-btn
            icon
            dark
            @click="dialog = false"
            :to="{ name: 'BookVehicle' }"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Checklist</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-container class="tw-mt-4 tw-mb-10">
          <v-card class="tw-px-2 md:tw-px-6">
            <v-card-title>
              <h3>User Details</h3>
            </v-card-title>
            <v-divider></v-divider>
            <v-row class="my-5 ml-3">
              <v-col col="12">
                Insured’s Name:
                {{ scanObject(bookedVehicle, 'clientInfo.name') }}</v-col
              >
              <v-col col="12">
                Reg No:
                {{
                  scanObject(bookedVehicle, 'vehicleInfo.registrationNumber')
                }}</v-col
              >
              <v-col col="12">
                Phone Number:
                {{ scanObject(bookedVehicle, 'clientInfo.phoneNumber') }}</v-col
              >
            </v-row>
            <v-divider></v-divider>
            <v-row class="my-2 ml-3">
              <v-col><strong>Checklist Preparation </strong> </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="my-2 ml-3">
              <v-col>
                Submit your Client’s Checklist form to confirm the list of
                things that came with the vehicle before repairs will also be
                available after repairs
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="my-2 ml-3">
              <v-col
                v-for="checklistItem in checklistItemsArray"
                :key="checklistItem"
                cols="12"
                md="6"
                lg="4"
                class="mx-auto"
              >
                <v-radio-group
                  :v-model="checklistItem"
                  row
                  :key="checklistItem"
                  :name="checklistItem"
                  :rules="[
                    (v) =>
                      v === true ||
                      v === false ||
                      `This ${checklistItem} field is required`,
                  ]"
                >
                  <p class="pt-6 pr-5">
                    {{ checklistItem.split('_').join(' ').toUpperCase() }}:
                  </p>

                  <v-radio
                    :name="checklistItem"
                    label="Yes"
                    :value="true"
                  ></v-radio>
                  <v-radio
                    :name="checklistItem"
                    label="No"
                    :value="false"
                  ></v-radio>
                </v-radio-group>
                <v-text-field
                  label="Comment"
                  :name="checklistItem + '_comment'"
                >
                </v-text-field>
              </v-col>

              <v-col col="12" md="6" class="mx-auto my-5">
                <v-btn
                  id="submitCheckListBtn"
                  block
                  large
                  color="primary"
                  type="submit"
                  >Submit</v-btn
                >
              </v-col>
            </v-row>
          </v-card>
        </v-container>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
/* eslint-disable no-unused-vars */
import { checklistItemsArray } from '@/data/checklistItems'
import axios from 'axios'

export default {
  data() {
    return {
      valid: true,
      dialog: true,
      notifications: false,
      sound: true,
      widgets: false,
      row: '',
      listComment: '',
      checklistItemsArray: checklistItemsArray,
    }
  },

  computed: {
    bookedVehicle() {
      return JSON.parse(this.scanObject(this.$route, 'query.booking', {}))
    },
  },
  methods: {
    submitChecklist(submitEvent) {
      if (!this.$refs.checklistform.validate()) {
        return
      }
      const bookingId = this.bookedVehicle.id
      const values = Object.fromEntries(new FormData(submitEvent.target))
      Object.keys(values).forEach(function (key) {
        const testCondition = key.endsWith('_comment')
        if (!testCondition) {
          return values[key] === 'true'
        }
      })

      axios
        .post(`/motor-assessment/api/checklist/${bookingId}`, values)
        .then(() => {
          this.$store.commit('SNACKBAR', {
            showing: true,
            text: 'Checklist was added successful',
            color: 'success',
          })

          this.$router.push({ name: 'BookVehicle' })
        })
        .catch(() => {
          this.$toasted.error('An error occurred, try again', {
            duration: 3000,
          })
        })
    },
  },
}
</script>
