export const checklistItemsArray = [
  'ignition_keys_keyless_fob',

  'radio_cassette',

  'cd_changer',

  'speakers',

  'heater_ac_knobs',

  'ash_tray',

  'computer_ecu',

  'alarm_remote',

  'rhs_mirror',

  'lhs_side_mirror',

  'rhs_side_remote',

  'interior_mirror',

  'safety_belts',

  'rear_boot_board',

  'console_box',

  'winder_machine',

  'door_switches',

  'main_switch',

  'head_rest',

  'cigar_lighter',

  'front_windscreen_glass',

  'rear_windscreen_glass',

  'door_glasses',

  'dashboard',

  'cluster_panel',

  'odometer_clock',

  'side_mirror_control',

  'pillar_mouldings',

  'door_mouldings',

  'front_bumper',

  'rear_bumper',

  'rear_grill',

  'lock_nuts',

  'windbreakers',

  'jumper_bables',

  'wheel_spanner_jack',

  'spare_wheel',

  'lifesaver_first_aid_box',

  'dipstick',

  'relays',

  'starter',

  'alternator',

  'lead_cables',

  'ignition_coils',

  'washer_bottle',

  'engine_oil_caps',

  'radiator_caps',

  'air_flow_sensor',

  'air_cleaner',

  'battery_make',

  'battery_clamp',

  'horn_set',

  'front_wiper',

  'rear_wiper',

  'wiper_panel_finishers',

  'wing_liner',

  'wheel_caps',

  'alloy_rim_caps',

  'mud_flaps',

  'tyres',

  'bull_bar',

  'head_lights',

  'tail_lights',

  'corner_lights',

  'emblem',

  'fog_lights',

  'roof_racks',

  'aerial_antenna',

  'pilot_mirror',

  'door_handles',

  'windscreen_moulding',

  'fuel_pump',

  'body_panels_dents',

  'body_kits',

  'front_rear_registration_plates',

  'propeller_shaft',

  'seats_and_upholstery',

  'mechanical_leakages',
]
